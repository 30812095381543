<template>
    <header class="checkout-header">
        <ContentContainer>
            <Grid cols="8" desktop="12">
                <Column cols="4" desktop="3" desktop-plus="4" class="col-logo">
                    <BaseLink :path="HOME_PATH" class="logo">
                        <MainLogo :name="eobuwieLogo" />
                    </BaseLink>
                </Column>
                <Column
                    cols="8"
                    desktop="6"
                    desktop-plus="4"
                    class="col-stepper"
                >
                    <CheckoutStepper :current-step="currentStep" />
                </Column>
                <Column
                    cols="4"
                    desktop="3"
                    desktop-plus="4"
                    class="safe-shopping"
                >
                    <Icon
                        :icon="LOCK_ICON"
                        :width="LOCK_ICON_SIZE"
                        :height="LOCK_ICON_SIZE"
                    />

                    {{ $t('Safe shopping') }}
                </Column>
            </Grid>
        </ContentContainer>
    </header>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import { LOGO_EOB_IMPORT_NAME } from '@localeConfig/keys';

import { HEADER_WISHLIST_CLICK } from '@analytics-types/Events';

import { HOME_PAGE_NAME } from '@router/names';

import DeliveryReturnInfo from '@mixins/DeliveryReturnInfo';

import { ContentContainer } from '@eobuwie-ui/components/ContentContainer/v1';
import { Column, Grid } from '@eobuwie-ui/components/Grid/v1';
import { Icon } from '@eobuwie-ui/components/Icon/v1';
import { Lock as LockIcon } from '@eobuwie-ui/icons/v1/other';

import BaseLink from '@atoms/BaseLink/BaseLink';

import CheckoutStepper from '@molecules/CheckoutStepper/CheckoutStepper';
import MainLogo from '@molecules/MainLogo/MainLogo';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');
const { mapGetters: mapCustomerGetters } = createNamespacedHelpers('customer');

export default {
    name: 'CheckoutHeaderV2',

    components: {
        Column,
        ContentContainer,
        Grid,
        Icon,
        BaseLink,
        CheckoutStepper,
        MainLogo,
    },

    mixins: [DeliveryReturnInfo],

    props: {
        currentStep: {
            type: Number,
            default: 1,
        },
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapConfigGetters(['locale']),
        ...mapCustomerGetters(['wishlistCount']),

        eobuwieLogo() {
            return this.$getLocaleConfigByKey(LOGO_EOB_IMPORT_NAME);
        },
    },

    beforeCreate() {
        this.HOME_PATH = this.$createPagePath({ name: HOME_PAGE_NAME });

        this.LOCK_ICON_SIZE = 16;

        this.LOCK_ICON = LockIcon;

        this.HEADER_WISHLIST_CLICK = HEADER_WISHLIST_CLICK;
    },
};
</script>

<style lang="scss" scoped>
.checkout-header {
    @apply mb-ui-3 bg-ui-swap-white;

    .content-container {
        @apply flex gap-y-ui-6 pt-ui-2 pb-ui-4;
    }

    .grid-row {
        @apply gap-y-ui-4;
    }

    .col-logo {
        @apply flex flex-grow items-center;
    }

    .col-stepper {
        @apply order-3 flex w-full justify-center;
    }

    .nav {
        @apply flex flex-grow justify-end;
    }

    .safe-shopping {
        @apply flex items-center justify-self-end gap-ui-3 text-ui-14 leading-ui-16;
    }

    .logo svg {
        @apply h-30p;
    }

    @screen mobile-only {
        .grid-row {
            @apply px-ui-4;
        }
    }

    @screen ui-desktop {
        @apply mb-ui-0 border-ui-swap-gray200 border-b-ui-1;

        .content-container {
            @apply flex items-center justify-between pt-ui-0 pb-ui-0 h-[92px];
        }

        .logo svg {
            @apply h-[36px];
        }

        .col-stepper {
            @apply order-none;
        }
    }
}
</style>
