<template>
    <header class="checkout-cart-header">
        <ContentContainer>
            <Grid cols="8" desktop="12">
                <Column cols="4" desktop="3" desktop-plus="4" class="col-logo">
                    <BaseLink :path="HOME_PATH" class="logo">
                        <MainLogo :name="eobuwieLogo" />
                    </BaseLink>
                </Column>
                <Column
                    cols="8"
                    desktop="6"
                    desktop-plus="4"
                    class="col-stepper"
                >
                    <CheckoutStepper :current-step="currentStep" />
                </Column>
                <Column cols="4" desktop="3" desktop-plus="4" class="col-nav">
                    <nav class="nav">
                        <BaseLink :path="ACCOUNT_PATH">
                            <ButtonIcon
                                :type="BUTTON_ICON_TYPE_BUTTON"
                                :variant="BUTTON_ICON_VARIANT_TERTIARY"
                                class="icon-button"
                            >
                                <Icon
                                    :icon="ACCOUNT_ICON"
                                    :width="ICON_SIZE"
                                    :height="ICON_SIZE"
                                />
                            </ButtonIcon>
                        </BaseLink>
                        <BaseLink
                            :path="WISHLIST_PATH"
                            @click.native="
                                $analytics.emit(HEADER_WISHLIST_CLICK)
                            "
                        >
                            <ButtonIcon
                                :type="BUTTON_ICON_TYPE_BUTTON"
                                :variant="BUTTON_ICON_VARIANT_TERTIARY"
                                class="icon-button"
                            >
                                <Icon
                                    :icon="FAVOURITE_ICON"
                                    :width="ICON_SIZE"
                                    :height="ICON_SIZE"
                                />
                                <template
                                    v-if="wishlistCount > 0"
                                    #circle-digit
                                >
                                    <CircleDigit
                                        :digit="wishlistCount"
                                        :variant="CIRCLE_DIGIT_VARIANT"
                                    />
                                </template>
                            </ButtonIcon>
                        </BaseLink>
                    </nav>
                </Column>
            </Grid>
        </ContentContainer>
    </header>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import { LOGO_EOB_IMPORT_NAME } from '@localeConfig/keys';

import { HEADER_WISHLIST_CLICK } from '@analytics-types/Events';

import {
    CUSTOMER_PAGE_NAME,
    HOME_PAGE_NAME,
    WISHLIST_PAGE_NAME,
} from '@router/names';

import DeliveryReturnInfo from '@mixins/DeliveryReturnInfo';

import {
    ButtonIcon,
    BUTTON_ICON_TYPES,
    BUTTON_ICON_VARIANTS,
} from '@eobuwie-ui/components/ButtonIcon/v1';
import {
    CircleDigit,
    CIRCLE_DIGIT_VARIANTS,
} from '@eobuwie-ui/components/CircleDigit/v1';
import { ContentContainer } from '@eobuwie-ui/components/ContentContainer/v1';
import { Column, Grid } from '@eobuwie-ui/components/Grid/v1';
import { Icon } from '@eobuwie-ui/components/Icon/v1';
import {
    Favourite as FavouriteIcon,
    Account as AccountIcon,
} from '@eobuwie-ui/icons/v1/other';

import BaseLink from '@atoms/BaseLink/BaseLink';

import CheckoutStepper from '@molecules/CheckoutStepper/CheckoutStepper';
import MainLogo from '@molecules/MainLogo/MainLogo';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');
const { mapGetters: mapCustomerGetters } = createNamespacedHelpers('customer');

export default {
    name: 'CheckoutCartHeader',

    components: {
        ButtonIcon,
        CircleDigit,
        Column,
        ContentContainer,
        Grid,
        Icon,
        BaseLink,
        CheckoutStepper,
        MainLogo,
    },

    mixins: [DeliveryReturnInfo],

    props: {
        currentStep: {
            type: Number,
            default: 1,
        },
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapConfigGetters(['locale']),
        ...mapCustomerGetters(['wishlistCount']),

        eobuwieLogo() {
            return this.$getLocaleConfigByKey(LOGO_EOB_IMPORT_NAME);
        },
    },

    beforeCreate() {
        this.ACCOUNT_PATH = this.$createPagePath({ name: CUSTOMER_PAGE_NAME });
        this.HOME_PATH = this.$createPagePath({ name: HOME_PAGE_NAME });
        this.WISHLIST_PATH = this.$createPagePath({ name: WISHLIST_PAGE_NAME });

        this.BUTTON_ICON_TYPE_BUTTON = BUTTON_ICON_TYPES.BUTTON;
        this.BUTTON_ICON_VARIANT_TERTIARY = BUTTON_ICON_VARIANTS.TERTIARY;
        this.CIRCLE_DIGIT_VARIANT = CIRCLE_DIGIT_VARIANTS.PRIMARY;

        this.ICON_SIZE = 24;

        this.FAVOURITE_ICON = FavouriteIcon;
        this.ACCOUNT_ICON = AccountIcon;

        this.HEADER_WISHLIST_CLICK = HEADER_WISHLIST_CLICK;
    },
};
</script>

<style lang="scss" scoped>
.checkout-cart-header {
    @apply mb-ui-3 bg-ui-swap-white;

    .content-container {
        @apply flex gap-y-ui-6 pt-ui-2 pb-ui-4;
    }

    .grid-row {
        @apply gap-y-ui-4;
    }

    .col-logo {
        @apply flex flex-grow items-center;
    }

    .col-stepper {
        @apply order-3 flex w-full justify-center;
    }

    .nav {
        @apply flex flex-grow justify-end;
    }

    .logo svg {
        @apply h-30p;
    }

    .icon-button {
        @apply flex items-center justify-center;
    }

    @screen mobile-only {
        .grid-row {
            @apply px-ui-4;
        }
    }

    @screen ui-desktop {
        @apply mb-ui-0 border-ui-swap-gray200 border-b-ui-1;

        .content-container {
            @apply flex items-center justify-between pt-ui-0 pb-ui-0 h-[92px];
        }

        .logo svg {
            @apply h-[36px];
        }

        .col-stepper {
            @apply order-none;
        }
    }
}
</style>
