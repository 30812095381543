<template>
    <footer v-if="isStaticBlocksAvailable" class="base-footer">
        <LazyHydrate :when-visible="true">
            <FooterTop :static-blocks="staticBlocks" />
        </LazyHydrate>
        <LazyHydrate :when-visible="true">
            <FooterBottom />
        </LazyHydrate>
        <template v-if="isNotBot && !isFetchingConsentsData">
            <ConsentsDataProvider
                v-if="isConsentsModalConfigured"
                #default="{
                    agreeAllConsents,
                    categories,
                    closeModal,
                    isModalOpen,
                    openModal,
                    providers,
                    setStoredCategories,
                    isTrustedPartnersModalOpen,
                    isCookieSettingsModalOpen,
                    openTrustedPartnersModal,
                    closeTrustedPartnersModal,
                    openCookieSettingsModal,
                    closeCookieSettingsModal,
                }"
            >
                <LoadCookieModal
                    :categories="categories"
                    :is-consents-modal-open="isModalOpen"
                    :is-trusted-partners-modal-open="isTrustedPartnersModalOpen"
                    :is-cookie-settings-modal-open="isCookieSettingsModalOpen"
                    :providers="providers"
                    @agree-all-consents="agreeAllConsents()"
                    @open-consents-modal="openModal()"
                    @close-consents-modal="closeModal()"
                    @open-trusted-partners-modal="openTrustedPartnersModal()"
                    @close-trusted-partners-modal="closeTrustedPartnersModal()"
                    @open-cookie-settings-modal="openCookieSettingsModal()"
                    @close-cookie-settings-modal="closeCookieSettingsModal()"
                    @set-stored-categories="setStoredCategories($event)"
                />
            </ConsentsDataProvider>
            <CheckCookieExist
                v-else
                #default="{ isCookieExist }"
                :cookie-name="cookiesModalCookieName"
            >
                <CookiesApprovals v-if="displayCookiesModal(isCookieExist)" />
            </CheckCookieExist>
        </template>
    </footer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import {
    PSB_FOOTER_CUSTOMER_SERVICE_MENU,
    PSB_FOOTER_REGULATIONS_MENU,
    PSB_FOOTER_COMPANY_MENU,
    PSB_FOOTER_GUIDES_MENU,
    PSB_FOOTER_FOLLOW_US,
} from '@configs/footer';
import { COOKIES_MODAL_COOKIE_NAME } from '@configs/storage';

import LazyHydrate from '@assets/vue-lazy-hydration/LazyHydrate';

import CheckCookieExist from '@functionals/CheckCookieExist/CheckCookieExist';

import FooterBottom from '@organisms/FooterBottom/FooterBottom';
import FooterTop from '@organisms/FooterTop/FooterTop';

const { mapGetters: mapCmsBlockGetters } = createNamespacedHelpers('cmsBlock');
const {
    mapState: mapConsentsState,
    mapActions: mapConsentsActions,
} = createNamespacedHelpers('consents');

export default {
    name: 'BaseFooter',

    components: {
        FooterBottom,
        FooterTop,
        CheckCookieExist,
        LazyHydrate,
        CookiesApprovals: () => ({
            component: import(
                /* webpackChunkName: "cookies-approvals" */
                '@organisms/CookiesApprovals/CookiesApprovals'
            ),
        }),

        ConsentsDataProvider: () => ({
            component: import(
                /* webpackChunkName: "load-cookie-modal" */
                '@functionals/ConsentsDataProvider/ConsentsDataProvider'
            ),
        }),

        LoadCookieModal: () => ({
            component: import(
                /* webpackChunkName: "load-cookie-modal" */
                '@functionals/LoadCookieModal/LoadCookieModal'
            ),
        }),
    },

    data() {
        return {
            isFetchingConsentsData: true,
        };
    },

    computed: {
        ...mapCmsBlockGetters(['staticBlockById']),
        ...mapConsentsState(['isConsentsModalConfigured']),

        cookiesModalCookieName() {
            return COOKIES_MODAL_COOKIE_NAME;
        },

        isStaticBlocksAvailable() {
            return Object.values(this.staticBlocks).some(
                block => block !== null && Object.keys(block).length
            );
        },

        staticBlocks() {
            const { staticBlockById } = this;

            return {
                customerServiceMenu:
                    staticBlockById(PSB_FOOTER_CUSTOMER_SERVICE_MENU) || {},

                regulationsMenu:
                    staticBlockById(PSB_FOOTER_REGULATIONS_MENU) || {},

                companyMenu: staticBlockById(PSB_FOOTER_COMPANY_MENU) || {},
                guidesMenu: staticBlockById(PSB_FOOTER_GUIDES_MENU) || {},
                followUs: staticBlockById(PSB_FOOTER_FOLLOW_US) || {},
            };
        },

        isNotBot() {
            return !this.$ua?.isBot;
        },
    },

    async beforeMount() {
        if (this.isNotBot) {
            await this.getConsentsModalConfig();
        }

        this.isFetchingConsentsData = false;
    },

    methods: {
        ...mapConsentsActions(['getConsentsModalConfig']),

        displayCookiesModal(cookieExists) {
            return cookieExists === false;
        },
    },
};
</script>

<style lang="scss" scoped>
.base-footer {
    @apply flex items-center flex-col flex-wrap bg-gray8;
}
</style>
