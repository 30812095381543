<template>
    <div class="main">
        <template v-if="isWebViewMobile">
            <main>
                <nuxt />
            </main>
        </template>

        <template v-else>
            <MarketingBarDataProvider />
            <HeaderWrapper />
            <main>
                <nuxt />
            </main>
            <Messages />
            <NewsletterSectionWrapper />
            <ChatWrapper />
            <BaseFooter />
            <GlobalModals />
        </template>
        <SmartBannerWrapper />
    </div>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex';

import ChatWrapper from '@molecules/ChatWrapper/ChatWrapper';
import Messages from '@molecules/Messages/Messages';
import NewsletterSectionWrapper from '@molecules/NewsletterSectionWrapper/NewsletterSectionWrapper';
import SmartBannerWrapper from '@molecules/SmartBanner/SmartBannerWrapper';

import BaseFooter from '@organisms/BaseFooter/BaseFooter';
import GlobalModals from '@organisms/GlobalModals/GlobalModals';
import HeaderWrapper from '@header-organisms/HeaderWrapper/HeaderWrapper';
import MarketingBarDataProvider from '@marketing-bar-components/organisms/MarketingBarDataProvider/MarketingBarDataProvider';

import BaseLayout from '@layouts/mixins/BaseLayout';
import MobileWebView from '@layouts/mixins/MobileWebView';

const { mapActions: mapSearchActions } = createNamespacedHelpers('search');

export default {
    name: 'DefaultLayout',

    components: {
        Messages,
        NewsletterSectionWrapper,
        BaseFooter,
        ChatWrapper,
        GlobalModals,
        MarketingBarDataProvider,
        HeaderWrapper,
        SmartBannerWrapper,
    },

    mixins: [BaseLayout, MobileWebView],

    computed: {
        ...mapState(['isMobile']),
    },

    methods: {
        ...mapSearchActions(['setIsSearchModalOpen']),
    },
};
</script>
